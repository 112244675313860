import bootstrap from 'bootstrap';

import lightgallery from 'lightgallery';
// import lgThumbnail from 'lg-thumbnail';
import lgAutoplay from 'lg-autoplay';
import lgVideo from 'lg-video';
// import lgFullscreen from 'lg-fullscreen';
import lgPager from 'lg-pager';
// import lgZoom from 'lg-zoom';
// import lgHash from 'lg-hash';
// import lgShare from 'lg-share';
import slick from 'slick-carousel';
import jqueryShares from './plugins/jquery.shares';
import preloading from './plugins/jquery.preloader';
import JqueryMatchHeight from 'jquery-match-height';
import Headroom from 'headroom.js';
window.Headroom = Headroom;