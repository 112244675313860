const init = () => {
    const HEADER_SELECTOR = '.header'

    const $header = $(HEADER_SELECTOR)

    const headroom = new Headroom($header[0], {
        offset: 70,
    });
  
      headroom.init();

}

export default {
    init
}