const init = () => {
    const CARD_MINISTRY = '.card__ministry';

    const $ministry = $(CARD_MINISTRY);

    $ministry.matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    })


}

export default {
    init
}