const init = () => {
    const SLIDER_SELECTOR = '.ministries__slider';
    const $slider = $(SLIDER_SELECTOR);

    $slider.slick({
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        dots: true,
    })
    
}

export default {
    init
}