const init = () => {
    const HERO_SLIDER_SELECTOR = '.hero-home__slider'

    const $slider = $(HERO_SLIDER_SELECTOR)

    $slider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 0,
        dots: true,
        arrows: false,
        appendDots: '.hero-home__dots',
        autoplay: true,
        autoplaySpeed: 7000,
        fade: true,
    })

}

export default {
    init
}