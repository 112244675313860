const init = () => {
    const WRAPPER_VIDEOS_SELECTOR = '#videos-preaching';

    const $preaching = $(WRAPPER_VIDEOS_SELECTOR);
    
    $preaching.lightGallery({
        selector: ".card__predication",
        download: false,
        showThumbByDefault: false,
        autoplayControls: false,
        zoom: false,
        scale: false,
        subHtmlSelectorRelative: true,
        showThumbByDefault: true,
        pager: false,
        hideBarsDelay: 100000,
        counter: false,
        youtubePlayerParams: {
          modestbranding: 0,
          showinfo: 0,
          rel: 0,
          controls: 0,
        },
    });


	$(function () {
		var head = document.head || document.getElementsByTagName('head')[0];
		var imgUri = $('#img-loading').data('img');
		var styleTag = document.createElement('style');
		styleTag.type = 'text/css';
		var content = document.createTextNode(`.lg-outer .lg-item { background-image: url('${imgUri}') }`);
		styleTag.appendChild(content);
		
		head.appendChild(styleTag);
	});

}

export default {
    init
}