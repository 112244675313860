! function(t) {
    "use strict";
    var a = function(t) {
            e(t)
        },
        e = function(t) {
            var a = o(t),
                e = a.url;
            if ("email" !== a.type) {
                var d = encodeURIComponent(t.attr("href"));
                "undefined" !== d ? e = e.replace("[data_href]", d) : n("warn", "jQuery-Shares: attr [href] not found, please add href to anchor in " + a.type)
            }
            if ("email" !== a.type && "facebook" !== a.type && "googleplus" !== a.type) {
                var c = encodeURIComponent(t.attr("data-text"));
                "undefined" !== c ? e = e.replace("[data_text]", c) : n("warn", "jQuery-Shares: attr [data-text] not found, please add data-text to anchor in " + a.type)
            }
            if ("pinterest" === a.type) {
                var s = encodeURIComponent(t.attr("data-image"));
                "undefined" !== s ? e = e.replace("[data_image]", s) : n("warn", "jQuery-Shares: attr [data-image] not found, please add data-image to anchor in " + a.type)
            }
            if ("email" === a.type) {
                var i = encodeURIComponent(t.attr("data-to"));
                "undefined" !== i ? e = e.replace("[data_to]", i) : (e = e.replace("[data_to]", ""), n("warn", "jQuery-Shares: attr [data-to] not found, please add data-to to anchor in " + a.type));
                var l = encodeURIComponent(t.attr("data-cc"));
                e = "undefined" !== l ? e.replace("[data_cc]", l) : e.replace("&cc=[data_cc]", "");
                var p = encodeURIComponent(t.attr("data-bcc"));
                e = "undefined" !== p ? e.replace("[data_bcc]", p) : e.replace("&bcc=[data_bcc]", "");
                var u = encodeURIComponent(t.attr("data-subject"));
                "undefined" !== u ? e = e.replace("[data_subject]", u) : (e = e.replace("&subject=[data_subject]", ""), n("warn", "jQuery-Shares: attr [data-subject] not found, please add data-subject to anchor in " + a.type));
                var h = encodeURIComponent(t.attr("data-body"));
                "undefined" !== h ? e = e.replace("[data_body]", h) : (e = e.replace("&body=[data_body]", ""), n("warn", "jQuery-Shares: attr [data-body] not found, please add data-body to anchor in " + a.type))
            }
            t.attr("href", e), "email" !== a.type && t.bind("click", r)
        },
        n = function(t, a) {
            window.console && ("warn" === t ? console.warn(a) : "log" === t && console.log(a))
        },
        o = function(t) {
            return t.hasClass("facebook") ? {
                type: "facebook",
                url: "https://www.facebook.com/sharer/sharer.php?u=[data_href]"
            } : t.hasClass("twitter") ? {
                type: "twitter",
                url: "https://twitter.com/intent/tweet?source=webclient&text=[data_text]&url=[data_href]"
            } : t.hasClass("linkedin") ? {
                type: "linkedin",
                url: "https://www.linkedin.com/shareArticle?mini=true&url=[data_href]&title=[data_text]"
            } : t.hasClass("google-plus") || t.hasClass("googleplus") ? {
                type: "googleplus",
                url: "https://plus.google.com/share?url=[data_href]"
            } : t.hasClass("pinterest") ? {
                type: "pinterest",
                url: "https://pinterest.com/pin/create/button/?url=[data_href]&media=[data_image]&description=[data_text]"
            } : !!t.hasClass("email") && {
                type: "email",
                url: "mailto:[data_to]?&cc=[data_cc]&bcc=[data_bcc]&subject=[data_subject]&body=[data_body]"
            }
        },
        r = function(a) {
            a.preventDefault(), d(t(this).attr("href"), 500, 310)
        },
        d = function(a, e, n) {
            var o, r;
            return o = t(window).width() / 2 - e / 2, r = t(window).height() / 2 - n / 2, window.open(a, "Compartir", "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" + e + ", height=" + n + ", top=" + r + ", left=" + o)
        };
    t.fn.shares = function() {
        return this.each(function() {
            if (t(this).hasClass("share-load")) return !1;
            t(this).addClass("share-load"), a(t(this))
        })
    }, t.shares = function() {
        t("body").find("a.share").each(function(a, e) {
            t(e).addClass("share-auto"), t(e).shares()
        })
    }
}(jQuery);