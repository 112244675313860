// import bootstrap from 'bootstrap';

import bootstrap from './bootstrap';

import heroHome from './app/hero-home';
import scrolling from './app/scrolling';
import ministries from './app/ministries';
import header from './app/header';
import shares from './app/shares';
import lightGallery from './app/light-gallery';
import matchHeight from './app/match-height';

let onStart = () => {
    heroHome.init();
    scrolling.init();
    ministries.init();
    header.init();
    shares.init();
    lightGallery.init();
    matchHeight.init();
}


let onPercent = ( percent ) => {
    $('.preloading-line span').width( percent + '%' );
    return false;
  }
  
  let onComplete = () => {
    $('.section-hero').addClass('hero-is-loading');
    $('#preloading').fadeOut('slow',function(){
      $('#preloading').remove();
        // $.animate();
    });
  }
  
  let ready = () => {
    $('body').preloader({
      onStart: onStart,
      onComplete: onComplete,
      onPercent: onPercent
    });
  }
  
  $(window).ready(ready);