const init = () => {
    $(function() {
      $('a[href^="#"]:not(.nav-link)').click(function() {
        if (
          location.pathname.replace(/^\//, "") ==
            this.pathname.replace(/^\//, "") &&
          location.hostname == this.hostname 
          // &&
          // $(this).hasClass("js-scroll-trigger")
        ) {
          var target = $(this.hash);
          target = target.length
            ? target
            : $("[name=" + this.hash.slice(1) + "]");
          if (target.length) {
            $("html,body").animate(
              {
                scrollTop: target.offset().top,
              },
              500
            );
            return false;
          }
        }
      });
    });
  };
  
  export default {
    init,
  };